div.CepDiv > *,
div.tiposImovel > div,
div.imovelOpcionais > div {
  margin-right: 10px;
}

button[class*='-removeButton-'] {
  margin-right: 4px !important;
}

button[class*='-removeButton-'],
button.showFirstButton,
button.descriptionButton {
  background-color: #00000054;
  padding: 6px !important;
  margin-top: 5px;
}

button[class*='-removeButton-']:hover,
button.showFirstButton:hover,
button.descriptionButton:hover {
  background-color: #00000085 !important;
}
div.tiposImovel > div {
  width: 206px;
}
