span.iconesMidiasSociais > svg {
  vertical-align: middle;
}

svg.iconeLinkMidasSociais {
  width: 0.5em;
  padding-left: 2;
}

a.linkIconesMidiasSociais {
  text-decoration: none;
}
